<template>
  <div class="p4b__parent">
    <div
      v-show="$store.state.appConfig.showLoader"
      class="axios-loader"
    >
      <loader />
    </div>
    <b-modal
      id="modal-payment-success"
      ref="payment-success-modal"
      modal-class="p4b__payment-status__modal"
      body-class="reduce-padding-0"
      size="470"
      centered
      no-stacking
      no-close-on-backdrop
      hide-footer
      header-class="front-modal"
    >
      <div class="p4b__payment-modal-icon">
        <b-avatar
          class="p4b__payment-modal-avatar-success"
          size="83.33px"
        >
          <span class="d-flex align-items-center">
            <feather-icon
              icon="CheckIcon"
              size="50"
            />
          </span>
        </b-avatar>
      </div>
      <div
        v-if="receipt && receipt.trxNo"
        class="p4b__payment-modal-content"
      >
        <h3>Payment successful</h3>
        <p>Thank you for the payment. Your receipt will be sent to your email <strong>{{ email }}.</strong></p>
        <b-button
          class="p4b__payment-btn-common"
          :to="{ name: 'file-payment-frontend-receipt-view', params: { id: receipt._id } }"
        >
          <b-img
            :src="noteIcon"
            alt="note-icon"
          />
          View receipt
        </b-button>
        <div class="p4b__modal-success-copy-text">
          <p>Receipt number: <strong>{{ receipt.trxNo }}</strong></p>
          <feather-icon
            icon="CopyIcon"
            height="22"
            width="19"
            cursor="pointer"
            color="#D6C691"
            @click="copyToClipboard(receipt._id)"
          />
        </div>
      </div>
      <div
        v-else
        class="p4b__payment-modal-content"
      >
        <h3>Payment successful</h3>
        <p>Thank you for the payment. Your receipt will be sent to your email <strong>{{ email }}.</strong></p>
      </div>
      <div class="clear" />
    </b-modal>

    <b-modal
      id="modal-payment-failure"
      ref="payment-failure-modal"
      modal-class="p4b__payment-status__modal"
      body-class="reduce-padding-0"
      size="470"
      centered
      no-stacking
      no-close-on-backdrop
      hide-footer
      header-class="front-modal"
    >
      <div class="p4b__payment-modal-icon">
        <b-avatar
          class="p4b__payment-modal-avatar-danger"
          size="83.33px"
        >
          <span class="d-flex align-items-center">
            <feather-icon
              icon="XIcon"
              size="50"
            />
          </span>
        </b-avatar>
      </div>
      <div class="p4b__payment-modal-content">
        <h3>Payment failed</h3>
        <p>Payment was unsuccessful. You were not charged. Please select other payment mode or try again.</p>
        <b-button
          class="p4b__payment-btn-common"
          :to="{ name: 'file-payment-frontend-preview', params: { token: $route.params.token } }"
        >
          Try again
        </b-button>
        <b-button
          class="p4b__payment-modal-link-btn"
          variant="link"
          :to="{ name: 'file-payment-frontend-preview', params: { token: $route.params.token } }"
        >
          Back to review payment
        </b-button>
      </div>
      <div class="clear" />
    </b-modal>
  </div>
</template>
<script>
import {
  BButton, BImg, BAvatar,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loader from '../Loader.vue'

export default {
  components: {
    BButton,
    BImg,
    BAvatar,

    Loader,
  },

  data() {
    return {
      // eslint-disable-next-line global-require
      noteIcon: require('@/assets/images/frontend/p4b/icons/note.png'),
      receipt: {},
      email: '',
    }
  },

  beforeMount() {
    document.body.classList.add('p4b__payment-bg')
    const { id } = this.$route.params
    if (id) {
      setTimeout(() => {
        this.$http.get(`front-end/payment/token/${id}/check/status`)
          .then(async response => {
            if (response.data.success) {
              this.email = response.data.email
              this.receipt = response.data.receipt || {}
              localStorage.setItem('customerReceiptDetail', JSON.stringify(this.receipt))
              await this.$nextTick()
              this.$root.$emit('bv::show::modal', 'modal-payment-success', '')
            } else {
              this.$root.$emit('bv::show::modal', 'modal-payment-failure', '')
            }
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }, 2000)
    }
  },
  methods: {
    async copyToClipboard(id) {
      try {
        const text = `${window.location.origin}/receipt/${id}/view`
        await navigator.clipboard.writeText(text)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Receipt has been copied',
            icon: 'BellIcon',
          },
        })
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Can not copy!',
            icon: 'BellIcon',
          },
        })
      }
    },
  },
}
</script>
